import React from "react";
import cvReact from "../assets/portfolio/cvvreact.png";
import marvelQuiz from "../assets/portfolio/marvelQuiz.png";
import ticket from "../assets/portfolio/ticket-tac.png";
import mymoviz from "../assets/portfolio/mymoviz.png";
import accueil from "../assets/portfolio/accueil.png"
import locapic from "../assets/portfolio/locapic.png"


const Portfolio = () => {
  const portfolios = [
    {
      id: 1,
      src: cvReact,
      demo: "https://lettre-motivation-dynamique.vercel.app/",
      code: "https://github.com/FaridHarres/Lettre_motivation_dynamique",
      titre: "Tech : React",
      desc: "Ce projet a été realisé en react. Vous avez la possibilité de cliquer sur demo afin d'acceder au cv. vous pouvez également telecharger le code source depuis mon github. (n'oubliez pas: npm install + npm start :-) )  "
    },
    {
      id: 2,
      src: marvelQuiz,
      demo: "https://github.com/FaridHarres/marvel-quizz-react.git",
      code: "https://github.com/FaridHarres/marvel-quizz-react.git",
      titre: "Tech : React et Firebase",
      desc: "Ce projet a été realisé en react et firebase. Vous avez la possibilité de cliquer sur demo afin d'acceder au quiz. Ce quiz a pour but d'évaluer vos connaissances dans le monde marvel, Amusez vous bien et bon courage :-). vous pouvez également telecharger le code source depuis mon github. (n'oubliez pas: npm install + npm start)  "


    },
    {
      id: 3,
      src: ticket,
      demo: "https://github.com/FaridHarres/ticket-tac-project",
      code: "https://github.com/FaridHarres/ticket-tac-project",
      titre: "Tech : Express NodeJS MongoDB",
      desc: "Ce projet a été realisé lors de ma formation à la capsule. Il s'agit d'un projet réalisé avec EXPRESS, NODE JS, MONGO DB. une demo est disponible. Il s'agit d'un site de revervation de billet de train, ce projet m'a permis de modélisé une base de donnée NOSQL, utilisé les agrégation et de pouvoir développer une jolie navbar scroll . vous pouvez également telecharger le code source depuis mon github. (n'oubliez pas: npm install + npm start)  "

    },
    {
      id: 4,
      src: mymoviz,
      desc: "Ce projet a été realisé lors de ma formation à la capsule. Il s'agit d'un projet réalisé avec EXPRESS, NODE JS, MONGO DB et REACT. une demo  n'est malheuresement pas disponible car l'api MovieDB est restreinte et nous ne pouvons pas mettre en production le site .Néamoins vous pouvez également telecharger le code source depuis mon github. (n'oubliez pas: npm install + npm start)  ",
      titre: "Tech : MERN",
      code: "https://github.com/FaridHarres/My-movie",
    },
    {
      id: 5,
      src: accueil,
      desc: "Ce projet a été realisé lors de mon stage à la suite de ma formation à l'AFPA. Green Mind est un site web E-commerce complet et sécurisé qui permettra à mon client fleuriste de commercialiser tous ses produits invendus et destiné à la destruction, et ainsi pouvoir participer à la préservation de l’environnement c'est ça avoir l’esprit GREEN-MIND. Via ce site, le fleuriste évitera le gaspillage, et permettra à des particuliers d’acquérir des plantes, bouquets etc... pour tout type d’évènement à prix réduit. Ce site donnera une image éco-responsable à mon client, avoir une meilleure visibilité et se moderniser en vendant des produits en ligne. Il s'agit d'un projet réalisé en PHP Symfony Et base de donnée MySQL, ce site a été fait en programation orienté objet. une demo  n'est malheuresement pas disponible . Néamoins vous pouvez également telecharger le code source depuis mon github. (n'oubliez pas: composer install )  ",
      titre: "Tech : PHP Symfony MySQL",
      code: "https://github.com/FaridHarres/Projet_greenMind_php",
    },
    {
      id: 6,
      src: locapic,
      desc: "ce projet a été réalisé en React Native, cette application permet à l'utilisateur de se géolocaliser, de pouvoir ajouter des point d'interet sur la map avec un titre et une description et pouvoir tchater en temps reel avec ses ami connécté grace au Web Sockets ",
      titre: "Tech : React native, web Sockets, React navigation ",
      code: "https://github.com/FaridHarres/locapic.git",
    },
    
  ];

  return (
    
    <div
      name="portfolio"
      className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen "
    >
      
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8 mt-64 ">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Portfolio
          </p>
          <p className="py-6">Un petit aperçu de mes différentes productions</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0 cards">
        

          {portfolios.map(({ id, src, demo, code, desc, titre }) => (
            <div key={id} className="shadow-md shadow-gray-600 rounded-lg ">
              <div className="card">
              <img
                src={src}
                alt=""
                className="rounded-md duration-200 hover:scale-105 h-auto grid row-start-0 row-span-3"
              />
              <p className="card-desc  text-white ">
                <h1 className="text-center font-bold ">{titre}</h1>
                <hr/>
                <br />
                {desc}
              </p>
              </div>
              <div className="flex items-center justify-end content-end ">
                <a href={demo} className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105 ">
                  Demo
                </a>
                <a href={code} className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">
                  Code
                </a>
                
              </div>
            </div>

         
          
          ))}       

        </div>
        <br />
        <div className="mb-80">
        <p >Plusieurs autres projets sympa sont disponible sur mon github, n'hesitez pas à faire un git clone.</p>
        </div>

      </div>
      
    </div>
  );
};

export default Portfolio;
