import React from "react";
import photocv from "../assets/photocvcircle.jpg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-scroll";
import Typed from 'react-typed';



const Home = () => {
  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800"
    >
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row ">
        <div className="flex flex-col justify-center h-full">
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
          développeur FULLSTACK 
          </h2>
          <p className="text-gray-500 py-4 max-w-md">
          <Typed
                    strings={["Hello, je me présente je m'appelle farid,  je suis developpeur FULLSTACK JavaScript.  N'hésitez pas à scroller vers le bas pour en savoir plus sur moi"]}
                    typeSpeed={40}
                />
          </p>

          

          <div>
            <Link
              to="portfolio"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
            >
              Portfolio
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>
          </div>
        </div>

        <div className="image rounded-full ml-32 " >
          <img
            src={photocv}
            alt="my profile"
            className="rounded-full mx-auto w-2/3 md:w-full "
            style={{width: "90%", height: "auto", border: "5px solid white"}}
            
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
