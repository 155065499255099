import React from "react";
import Typed from "react-typed";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            A propos de moi
          </p>
        </div>

        <p className="text-xl mt-5">

          Après 7 ans d'experience dans la gestion de patrimoine (immobilier et financier), j'ai décidé de changer de carriere. Ma reconversion professionnelle se porte dans le domaine du web. Au dela d'une reconversion, je me suis trouvé une passion. Je suis fasciné par les avancées technologiques, le design et toutes opportunités que le web a à offrir. Particulierement curieux et autodidacte, j'adore me plonger dans les résolutions de problèmes algorithmiques mais aussi sur les problématiques d'UX/UI.
        </p>
        <br />
        <p className="text-xl">

          Aprés avoir parcouru des heures de tutoriels et de nombreuses pages de documentations, j'ai décidé d'écouter mon coeur. J'ai donc cloturé les comptes de la finance, et rejoint dans un premier temps l'AFPA afin de consolider mes connaissances en HTML, CSS, JAVASCRIPT, et pouvoir également apprendre des langages tel que le PHP, le framework Symfony ainsi que les bases de données SQL, suite à cette formation j'ai obtenu le titre RNCP de niveau 5 "developpeur web et web mobile. (bac +2)".
          J'ai ensuite rejoint un bootcamp intensif Fullstack Javascript à LA CAPSULE PARIS. Cette formation diplomante (bac +4) "titre RNCP de niveau 6 chef de projet d'applications web et web mobile" m'a permis d'apprendre de nouvelles technologies tel que la MERN stack via plusieurs projets et  exercices algorithmiques, et pouvoir mettre en pratique des méthodes de gestion de projet tels que la méthode AGILE SCRUM. 
          La diversité des formations auxquelles j'ai eu la chance de participer au cours de l'année m'ont permis de devenir un developpeur polyvalent tout en me préparant à travailler au sein d'une entreprise et d'une equipe de developpeur.
        </p>

        <br />
        {/* <p className="text-xl mb-32">
          Je suis desormais à la recherche d'un poste afin de m'enrichir de l'experience du terrain et d'assouvir ma soif du code.
          Si vous cherchez, un jeune developpeur FULLSTACK, motivé, curieux, avec une forte appétence pour le frontend et le backend, n'hesitez pas et venez me rencontrer

        </p> */}
      


      </div>
    </div>
  );
};

export default About;
