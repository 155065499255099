import About from "./components/About";
//import AllExperience from "./components/AllExperience";
import Backend from "./components/backend";
import Contact from "./components/Contact";
import ExperienceEtCompetences from "./components/Experience";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import OtherSkills from "./components/OtherSkills";
import Portfolio from "./components/Portfolio";
import SocialLinks from "./components/SocialLinks";
import { useState, useEffect, useLayoutEffect } from "react";
import phone from './assets/phone.png'

function App() {

  const [windowSize, _setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ])

  const [tailleEcran, settailleEcran] = useState(false)


  useEffect(() => {

    if (window.innerWidth < 820 || window.innerHeight< 720) {
        //console.log("je suis dans le if ")
        //console.log(windowSize)

        settailleEcran(true)
        ///console.log(tailleEcran)


      }else{
        settailleEcran(false)
      }
    window.addEventListener('resize', function () {
      
      if (window.innerWidth < 820 || window.innerHeight< 720) {
        //console.log("je suis dans le if ")
        //console.log(windowSize)

        settailleEcran(true)
        //console.log(tailleEcran)


      }else{
        settailleEcran(false)
      }
     // console.log(window.innerWidth, window.innerHeight);
    });
  }, [windowSize])





  if (tailleEcran) {
    return (
      <div
      name="experiences"
      className="w-full flex justify-center items-center h-screen bg-gradient-to-b from-black to-gray-800 p-4 text-white bottom-10 "
    >
      <div className="w-full  gap-8 text-center py-8 px-12 sm:px-0 mb-20 " >
        
        <div
          
          className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg m-5 shadow-red-500`}
        >
          
        <img src={phone} alt="" className="w-20 mx-auto" />
        <br />
      
        <h1>Votre fenêtre est trop petite.</h1>
          
        
          <br />
          
          <p>Le format d'ecran n'est pas adapté, veuillez naviguer depuis une tablette ou un ordinateur*. </p><br/>
          <br/>
            <p>*Si la page ne s'affiche pas depuis votre ordinateur, veuillez appuyer sur : <br/>
            <ul>
            <li> Ctrl et - pour Windows et Linux  </li>
            <li> Ctrl et - pour Chrome OS   </li>
            <li> ⌘ et - pour Mac </li>
            </ul>
          </p>
        </div>
      </div>
      
    </div>
    )

  } else {

    return (
      <div>
        <NavBar />
        <Home />
        <About />
        <Portfolio />
        <ExperienceEtCompetences />
        <Backend />
        <OtherSkills />
        {/* <AllExperience/> */}

        <Contact />

        <SocialLinks />
      </div>
    );

  }


}

export default App;
