import React from "react";


import github from "../assets/github.png";
import agil from "../assets/agil.png";
import wordpress from "../assets/wordpress.png"
import figma from "../assets/figma.png"
import heroku from "../assets/heroku.png"


const Backend = () => {


    
  var otherSkills =[
    {
        id: 1,
        src: github,
        title: "GitHub",
        style: "shadow-gray-400",
      },
      {
        id: 2,
        src: agil,
        title: "Methode AGILE",
        style: "shadow-sky-400",
      },
      {
        id: 3,
        src: wordpress,
        title: "WordPress",
        style: "shadow-blue-400",
      },
      {
        id: 4,
        src: figma,
        title: "Figma",
        style: "shadow-amber-900",
      },
      {
        id: 5,
        src: heroku,
        title: "Heroku",
        style: "shadow-violet-700",
      },
    
    
  ]

  return (
    <div
      
      className="bg-gradient-to-b from-gray-800 to-black w-full h-screen"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
    
        <div className="mt-64">
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
          Autres Competences : 
          </p>
          
        </div>
       
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0 mb-20 ">
          {otherSkills.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg m-5 ${style}`}
            >
              <img src={src} alt="" className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
          
        </div>

      </div>
      
    </div>
  );
};

export default Backend;